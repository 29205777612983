<template>
	<div>
		<!-- 头部logo -->
		<moveHeadLogo @navigation="navigation"></moveHeadLogo>
		
		<!-- 头部导航 -->
		<moveNavigation :headTitle="headTitle" :headImg="headImg"></moveNavigation>
		
		<!-- 菜单导航 -->
		<moveMeauDialog :meauValue="navigationvalue" @meauFn="menu"></moveMeauDialog>
		
		<!-- 公告详情 -->
		<el-row class="list">
			<noticesdetailsdata></noticesdetailsdata>
		</el-row>
		
		<!-- 底部导航 -->
		<moveBottomNavigation></moveBottomNavigation>
	</div>
</template>

<script>

// 头部组件
import moveHeadLogo from '@/components/move/moveHeadLogo.vue'
// 头部导航
import moveNavigation from '@/components/move/moveNavigation.vue'
// 底部导航
import moveBottomNavigation from '@/components/move/moveBottomNavigation.vue'
// 菜单导航
import moveMeauDialog from '@/components/move/moveMeauDialog.vue'
// 公告详情
import noticesdetailsdata from '@/components/details/noticesdetailsdata.vue'
export default
{
	data()
	{
		return{
			// 头部导航标题
			headTitle:'BREAKING NEWS & NOTICES',
			// 头部导航图片
			headImg:'/index/notice.png',
			// 导航值
			navigationvalue:false,
		}
	},
	components:
	{
		moveHeadLogo,
		moveNavigation,
		moveBottomNavigation,
		moveMeauDialog,
		noticesdetailsdata
	},
	created()
	{
		this.headTitle=this.$route.query.headTitle;
		this.headImg=this.$route.query.headImg;
	},
	methods:
	{
		// 头部传来的数据
		navigation(v)
		{
			this.navigationvalue=v;
			console.log(this.navigationvalue,'头部传递菜单导航')
		},
		// 遮罩层传回来的数据
		menu(v)
		{
			this.navigationvalue=v;
		}
	}
}

</script>

<style scoped>
	.list{
		width: 90%;
		margin-left: 5%;
	}
</style>